<template>
  <v-container
    id="formation-detail"
    fluid
    tag="section"
  >
    <base-material-card
      id="card-course-detail"
      color="primary"
    >
      <template v-slot:heading>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <div class="display-2">
              {{ formation.intitule }} ({{ formation.reference }})
            </div>
            <div>
              {{ dateDebutFormatted }} - {{ dateFinFormatted }}
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="align-right"
          >
            <v-btn
              color="blue"
              class="ma-2"
              @click="$router.push({ name: 'ModifierFormation' })"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              color="gray"
              light
              class="ma-2"
              @click="$router.push({ name: 'CreerFormation', params: { formation: formation } });"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>
              Dupliquer
            </v-btn>
            <v-btn
              v-if="formation.etat === 'OUVERT'"
              color="green"
              class="ma-2"
              @click="confirmerFormation()"
              :disabled="confirmerDisabled"
            >
              <v-icon left>
                mdi-checkbox-marked
              </v-icon>
              Confirmer
            </v-btn>
            <v-btn
              v-if="formation.etat === 'OUVERT'"
              color="red darken-1"
              class="ma-2"
              @click="fermerFormation()"
              :disabled="fermerDisabled"
            >
              <v-icon left>
                mdi-close-box
              </v-icon>
              Fermer
            </v-btn>
            <v-btn
              v-if="formation.etat === 'CONFIRME' || formation.etat === 'ENCOURS'"
              color="red darken-1"
              class="mx-2"
              @click="dialogAnnul = true"
              :disabled="annulerDisabled"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              Annuler
            </v-btn>
            <v-btn
              v-if="['FERME', 'ANNULE'].includes(formation.etat)"
              color="red darken-1"
              class="mx-2"
              @click="dialogSupprimer = true"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-simple-table
            class="table-formation-detail"
          >
            <tbody>
              <tr>
                <th>
                  <v-icon>mdi-barcode-scan</v-icon>
                </th>
                <th>
                  Référence
                </th>
                <td>
                  {{ formation.reference }}
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-calendar-today</v-icon>
                </th>
                <th>
                  Date de début
                </th>
                <td>
                  {{ dateDebutFormatted }}
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-calendar</v-icon>
                </th>
                <th>
                  Date de fin
                </th>
                <td>
                  {{ dateFinFormatted }}
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-timer-sand</v-icon>
                </th>
                <th>
                  Durée
                </th>
                <td>
                  {{ formation.dureeJours }} jour<span v-if="formation.dureeJours >= 2">s</span>
                  ({{ formation.dureeHeures.toLocaleString('fr-FR') }} heure<span v-if="formation.dureeHeures >= 2">s</span>)
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-account-tie</v-icon>
                </th>
                <th>
                  Formateur<span v-if="formateurs.length > 1">s</span>
                </th>
                <td v-if="formateurs.length > 0">
                  <span
                    v-for="(formateur, i) in formateurs"
                    :key="formateur.id"
                  >
                    <template v-if="i > 0">, </template>
                    <router-link :to="{ name: 'FormateurDetail', params: { id: formateur.id } }">
                      {{ formateur.prenom }} {{ formateur.nom }}</router-link>
                  </span>
                </td>
                <td v-if="formateurs.length === 0">
                  <i>Aucun formateur défini</i>
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-account-group</v-icon>
                </th>
                <th>
                  Nombre de stagiaires
                </th>
                <td>
                    {{ formation.nbStagiaires }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        <v-simple-table
            class="table-formation-detail"
          >
            <tbody>
              <tr>
                <th>
                  <v-icon>mdi-traffic-light</v-icon>
                </th>
                <th>
                  État
                </th>
                <td v-html="displayEtat">
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-flare</v-icon>
                </th>
                <th>
                  Type
                </th>
                <td v-html="displayType"></td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-book-open-page-variant-outline</v-icon>
                </th>
                <th>
                  Cours associé
                </th>
                <td>
                  <span>
                    <router-link :to="{ name: 'CoursDetail', params: { id: formation.cours.id }}">{{ formation.cours.name }}</router-link>
                  </span>
                </td>
              </tr>
              <tr v-if="formation.type === 'INTRA' && formation.client">
                <th>
                  <v-icon>mdi-handshake-outline</v-icon>
                </th>
                <th>
                  Client
                </th>
                <td>
                  <router-link :to="{ name: 'ClientDetail', params: { id: formation.client.id } }">
                    {{ formation.client.nomCommercial }}
                  </router-link>
                </td>
              </tr>
              <tr v-if="formation.type === 'INTRA' && !formation.client">
                <th>
                  <v-icon>mdi-handshake-outline</v-icon>
                </th>
                <th>
                  Client
                </th>
                <td>
                  <i>Aucun client défini</i>
                </td>
              </tr>
              <tr v-if="formation.type === 'SSTRT'">
                <th>
                  <v-icon>mdi-handshake-outline</v-icon>
                </th>
                <th>
                  Organisme
                </th>
                <td>
                  <router-link :to="{ name: 'ClientDetail', params: { id: formation.client.id } }">
                    {{ formation.client.nomCommercial }}
                  </router-link>
                </td>
              </tr>
              <tr v-if="formation.type === 'SSTRT' && formation.nomClientFinal">
                <th>
                  <v-icon>mdi-handshake-outline</v-icon>
                </th>
                <th>
                  Client final
                </th>
                <td>
                  {{ formation.nomClientFinal }}
                </td>
              </tr>
              <tr v-if="formation.type === 'INTER'">
                <th>
                  <v-icon>mdi-handshake-outline</v-icon>
                </th>
                <th>
                  Client<span v-if="clients.length > 1">s</span>
                </th>
                <td>
                  <span
                    v-for="(client, i) in clients"
                    :key="client.id"
                  >
                    <template v-if="i > 0">, </template>
                    <router-link :to="{ name: 'ClientDetail', params: { id: client.id } }">
                      {{ client.nomCommercial }}</router-link>
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </th>
                <th>
                  Lieu
                </th>
                <td
                  v-html="formatAdresse()"
                  style="white-space: pre-line;"
                >
                </td>
              </tr>
              <tr
                v-if="formation.commentaires"
              >
                <th>
                  <v-icon>mdi-comment-processing-outline</v-icon>
                </th>
                <th>
                  Commentaires
                </th>
                <td>
                  {{ displayCommentaires() }}
                  <span
                    v-if="formation.commentaires.length > 140 && !commentairesSeeMore"
                    class="see-more-btn"
                    @click="commentairesSeeMore = true"
                  >
                    Voir plus
                  </span>
                  <span
                    v-if="formation.commentaires.length > 140 && commentairesSeeMore"
                    class="see-more-btn"
                    @click="commentairesSeeMore = false"
                  >
                    Voir moins
                  </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <!--
        <v-col
          cols="12"
          md="5"
        >
          <base-material-card
            title="Actions"
          >
            <v-card-text class="text-center">
              <v-btn
                v-if="formation.type !== 'SSTRT' && (formation.etat === 'CONFIRME' && !formation.convocationsEnvoyees)"
                color="green"
                rounded
                class="ma-2"
                @click="convoquer()"
                :disabled="convocationsDisabled"
              >
                <v-icon left>
                  mdi-bell
                </v-icon>
                Envoyer les convocations
              </v-btn>
              <v-btn
                v-if="formation.type !== 'SSTRT' && (formation.etat === 'CONFIRME' && formation.convocationsEnvoyees)"
                color="green"
                rounded
                class="ma-2"
                disabled
              >
                <v-icon left>
                  mdi-bell
                </v-icon>
                Convocations envoyées
              </v-btn>
              <v-btn
                v-if="formation.type !== 'SSTRT' && ['CONFIRME', 'ENCOURS', 'TERMINE'].includes(formation.etat)"
                color="#00838f"
                rounded
                class="ma-2"
                @click="downloadEmargement()"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Télécharger la feuille d'émargement
              </v-btn>
              <v-btn
                v-if="formation.etat === 'TERMINE' && formation.type !== 'SSTRT' && !formation.lienAttestations"
                color="indigo"
                rounded
                class="ma-2"
                @click="genererAttestations()"
                :disabled="genererAttestationsDisabled"
              >
                <v-icon left>
                  mdi-school
                </v-icon>
                Générer les attestations
              </v-btn>
              <v-btn
                v-if="formation.etat === 'TERMINE' && formation.type !== 'SSTRT' && formation.lienAttestations"
                color="indigo"
                rounded
                class="ma-2"
                @click="downloadAttestations()"
                :disabled="downloadAttestationsDisabled"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Télécharger les attestations
              </v-btn>
              <v-btn
                v-if="formation.etat === 'TERMINE' && formation.type !== 'SSTRT' && formation.lienAttestations && !formation.attestationsEnvoyees"
                color="indigo"
                rounded
                class="ma-2"
                @click="sendAttestations()"
                :disabled="sendAttestationsDisabled"
              >
                <v-icon left>
                  mdi-send
                </v-icon>
                Envoyer les attestations
              </v-btn>
              <v-btn
                v-if="formation.etat === 'TERMINE' && formation.type !== 'SSTRT' && formation.lienAttestations && formation.attestationsEnvoyees"
                color="indigo"
                rounded
                class="ma-2"
                disabled
              >
                <v-icon left>
                  mdi-send
                </v-icon>
                Attestations envoyées
              </v-btn>
              <v-btn
                v-if="formation.etat === 'ENCOURS' || formation.etat === 'TERMINE'"
                color="teal"
                rounded
                class="ma-2"
                @click="$router.push({ name: 'CreerFacture' , params: { formation: formation } })"
              >
                <v-icon left>
                  mdi-cash-multiple
                </v-icon>
                Éditer une facture
              </v-btn>
            </v-card-text>
          </base-material-card>
        </v-col>
        -->
      </v-row>
    </base-material-card>

    <base-material-card
      class="px-5 py-3"
      color="primary"
    >
      <div class="py-2"></div>
      <template v-slot:heading>
        <v-tabs
          v-model="tabs"
          background-color="transparent"
          slider-color="white"
          show-arrows
        >
          <v-tab
            class="mr-3"
          >
            <v-icon class="mr-2">
              mdi-account-school
            </v-icon>
            Stagiaires
          </v-tab>
          <v-tab
            class="mr-3"
          >
            <v-icon class="mr-2">
              mdi-calendar-clock
            </v-icon>
            Créneaux
          </v-tab>
          <v-tab
            class="mr-3"
          >
            <v-icon class="mr-2">
              mdi-currency-eur
            </v-icon>
            Budget
          </v-tab>
          <v-tab
            class="mr-3"
          >
            <v-icon class="mr-2">
              mdi-file-document-multiple-outline
            </v-icon>
            Documents
          </v-tab>
          <v-tab
            class="mr-3"
          >
            <v-icon class="mr-2">
              mdi-cash-multiple
            </v-icon>
            Factures
          </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items
        v-model="tabs"
        class="transparent"
      >
        <v-tab-item
          class="tab-stagiaires"
          :key="0"
        >
          <v-row>
            <v-col>
              <v-data-table
                :headers="headersStagiaires"
                :items="formation.stagiaires"
              >
                <template v-slot:[`item.sexe`]="{ item }">
                  <span v-if="item.sexe === 'm'">M.</span>
                  <span v-if="item.sexe === 'f'">Mme</span>
                </template>
                <template v-slot:[`item.nom`]="{ item }">
                  <span
                    v-if="item.nom"
                  >
                    {{ item.nom }}
                  </span>
                  <span
                    v-else
                    class="a-completer"
                  >
                    À compléter
                  </span>
                </template>
                <template v-slot:[`item.prenom`]="{ item }">
                  <span
                    v-if="item.prenom"
                  >
                    {{ item.prenom }}
                  </span>
                  <span
                    v-else
                    class="a-completer"
                  >
                    À compléter
                  </span>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <span
                    v-if="item.email"
                  >
                    {{ item.email }}
                  </span>
                  <span
                    v-else
                    class="a-completer"
                  >
                    Aucun email renseigné
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    class="mx-1"
                    small
                    color="blue"
                    @click="editStagiaire(item)"
                  >
                    <v-icon>
                      mdi-account-edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    small
                    color="red"
                    @click="deleteStagiaire(item)"
                  >
                    <v-icon>
                      mdi-account-remove
                    </v-icon>
                  </v-btn>
                </template>
                <template
                  v-if="formation.type !== 'SSTRT'"
                  v-slot:[`item.convoquer`]="{ item }"
                >
                  <v-btn
                    color="primary"
                    small
                    @click="convoquerIndiv(item)"
                  >
                    <v-icon
                      class="mr-1"
                      small
                    >
                      mdi-bell
                    </v-icon>
                    Convoquer
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row
            v-if="!formNewStagiaire && !formExistingStagiaire"
          >
            <v-col
              cols="12"
            >
              <v-btn
                color="primary"
                class="mx-1"
                @click="showFormNewStagiaire()"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-account-plus
                </v-icon>
                Ajouter un nouveau stagiaire
              </v-btn>
              <v-btn
                color="primary"
                class="mx-1"
                @click="showFormExistingStagiaire()"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-account-arrow-right
                </v-icon>
                Ajouter un stagiaire existant
              </v-btn>
              <v-btn
                color="primary"
                class="mx-1"
                @click="addStagiairesAnonymes()"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-account-question
                </v-icon>
                Ajouter
                <v-text-field
                  outlined
                  dense
                  hide-details
                  class="mx-1 input-stagiaires-anonymes"
                  type="number"
                  min="1"
                  v-model="nbStagiairesAnonymes"
                  @click.stop=""
                />
                stagiaire{{ nbStagiairesAnonymes > 1 ? 's' : '' }} anonyme{{ nbStagiairesAnonymes > 1 ? 's' : '' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="formNewStagiaire"
            id="form-new-stagiaire"
          >
            <v-col>
              <v-card-title
                v-if="!stagiaire.id"
              >
                Ajouter un stagiaire à la formation
              </v-card-title>
              <v-card-title
                v-else
              >
                Mise-à-jour du stagiaire
              </v-card-title>
              <v-form
                method="post"
                ref="stagiaire"
                @submit.prevent="validate"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-select
                        outlined
                        v-model="stagiaire.sexe"
                        :items="titreStagiairesItems"
                        item-text="titre"
                        item-value="sexe"
                        label="Titre"
                        single-line
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        outlined
                        label="Nom *"
                        v-model="stagiaire.nom"
                        :rules="[v => !!v || 'Indiquez le nom du stagiaire']"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        outlined
                        label="Prénom *"
                        v-model="stagiaire.prenom"
                        :rules="[v => !!v || 'Indiquez le prénom du stagiaire']"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        outlined
                        label="E-mail"
                        type="email"
                        v-model="stagiaire.email"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        outlined
                        label="Téléphone"
                        type="tel"
                        v-model="stagiaire.telephone"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-autocomplete
                        outlined
                        label="Statut du stagiaire *"
                        v-model="stagiaire.type"
                        :items="typeStagiairesItems"
                        item-text="intitule"
                        item-value="type"
                        clearable
                        :rules="[v => !!v || 'Indiquez le statut du stagiaire']"
                        :disabled="formation.type === 'INTRA'"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="['SALARIE', 'APPRENTI'].includes(stagiaire.type)"
                    >
                      <v-autocomplete
                        outlined
                        label="Société *"
                        v-model="stagiaire.societe"
                        :items="allClients"
                        item-text="nomCommercial"
                        item-value="id"
                        return-object
                        clearable
                        :rules="[v => !!v || 'Indiquez la société']"
                        :disabled="formation.type === 'INTRA' && formation.client"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        label="Adresse"
                        v-model="stagiaire.adresse.adressePostale"
                        class="purple-input"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        outlined
                        label="Code postal"
                        class="purple-input"
                        v-model="stagiaire.adresse.codePostal"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        outlined
                        label="Ville"
                        class="purple-input"
                        v-model="stagiaire.adresse.ville"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        outlined
                        label="Pays"
                        v-model="stagiaire.adresse.pays"
                        class="purple-input"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="gray"
                        class="mx-1"
                        @click="formNewStagiaire = false"
                      >
                        Retour
                      </v-btn>
                      <v-btn
                        v-if="!stagiaire.id"
                        color="primary"
                        class="mx-1"
                        type="submit"
                      >
                        Ajouter le stagiaire
                      </v-btn>
                      <v-btn
                        v-if="stagiaire.id"
                        color="primary"
                        class="mx-1"
                        type="submit"
                      >
                        Mettre à jour le stagiaire
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
          <v-row
            v-if="formExistingStagiaire"
            id="form-existing-stagiaire"
          >
            <v-col>
              <v-card-title>
                Ajouter un stagiaire existant à la formation
              </v-card-title>
              <v-form
                ref="stagiaireExistant"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      md="8"
                    >
                      <v-autocomplete
                        outlined
                        label="Saisissez le nom du stagiaire"
                        v-model="stagiaire"
                        :items="allStagiaires"
                        :search-input.sync="searchExistingStagiaire"
                        :menu-props="{ ...(!searchExistingStagiaire && {value: false}) }"
                        item-text="nom"
                        return-object
                        hide-selected
                        clearable
                      >
                        <template
                          slot="selection"
                          slot-scope="data"
                        >
                          {{ data.item.nom }} {{ data.item.prenom }}
                          <span
                            v-if="data.item.email"
                            class="ml-1 color-gray"
                          >
                            ({{ data.item.email }})
                          </span>
                          <v-chip
                            v-if="data.item.societe && ['SALARIE', 'APPRENTI'].includes(data.item.type)"
                            small
                            class="ml-2"
                          >
                            {{ data.item.societe.nomCommercial }}
                          </v-chip>
                        </template>
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          {{ data.item.nom }} {{ data.item.prenom }}
                          <span
                            v-if="data.item.email"
                            class="ml-1 color-gray"
                          >
                            ({{ data.item.email }})
                          </span>
                          <v-chip
                            v-if="data.item.societe && ['SALARIE', 'APPRENTI'].includes(data.item.type)"
                            small
                            class="ml-2"
                          >
                            {{ data.item.societe.nomCommercial }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="gray"
                        class="mx-1"
                        @click="formExistingStagiaire = false"
                      >
                        Retour
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="addExistingStagiaire()"
                      >
                        Ajouter le stagiaire
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          :key="1"
        >
          <v-data-table
            id="table-creneaux"
            :headers="headersCreneaux"
            :items="formation.creneaux"
            :items-per-page="10"
            dense
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:[`item.heureDebut`]="{ item }">
              {{ item.heureDebut.substring(0, 5) }}
            </template>
            <template v-slot:[`item.heureFin`]="{ item }">
              {{ item.heureFin.substring(0, 5) }}
            </template>
            <template v-slot:[`item.formateur`]="{ item }">
              <span v-if="item.formateur">
                {{ item.formateur.prenom.substring(0, 1) }}. {{ item.formateur.nom }}
              </span>
              <span v-if="!item.formateur">
                <i>Aucun</i>
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          :key="2"
        >
          <v-card
            class="px-4 py-6"
          >
            <v-card-title>
              Recettes
            </v-card-title>
            <h4>Coûts pédagogiques</h4>
            <v-card-text>
              <v-simple-table
                v-if="formation.type === 'INTER'"
                id="table-recettes-budgets-inter"
              >
                <thead>
                  <tr>
                    <th>Client</th>
                    <th>Stagiaires</th>
                    <th>Prix par stagiaire</th>
                    <th>Total client</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in clients"
                    :key="client.id"
                  >
                    <td>{{ client.nomCommercial }}</td>
                    <td>
                      <span
                        v-for="(stagiaire, i) in getStagiairesOfClient(client.id)"
                        :key="stagiaire.id"
                      >
                        <template v-if="i > 0">, </template>
                        <template v-if="stagiaire.prenom || stagiaire.nom">{{ stagiaire.prenom }} {{ stagiaire.nom }}</template>
                        <template v-else><i class="a-completer">À compléter</i></template>
                      </span>
                      ({{ getStagiairesOfClient(client.id).length }})
                    </td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        class="mt-0 purple-input input-budget no-arrows"
                        v-model="formation.budgets.find(budget => budget.client.id === client.id).cout"
                        type="number"
                        step=".01"
                        suffix="€"
                        @blur="updateBudgets()"
                      >
                      </v-text-field>
                    </td>
                    <td>{{ toEuro(getBudgetOfClient(client.id) * getStagiairesOfClient(client.id).length) }} HT</td>
                  </tr>
                </tbody>
                <tfoot
                  class="table-budget-total"
                >
                  <th
                    colspan="3"
                  >
                    Total coûts pédagogiques
                  </th>
                  <td>
                    {{ toEuro(getCoutsPedagogiquesInter()) }} HT
                  </td>
                </tfoot>
              </v-simple-table>
              <v-simple-table
                v-if="['INTRA', 'SSTRT'].includes(formation.type) && formation.client"
                id="table-recettes-budgets-intra-sstrt"
              >
                <thead>
                  <tr>
                    <th>Mode de calcul</th>
                    <th>{{ displayModeCalcul}}</th>
                    <th>Total coûts pédagogiques</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-radio-group
                        v-model="modeCalculBudget"
                        row
                        @change="setBudgetModeCalcul();updateBudgets()"
                      >
                        <v-radio
                          label="Prix par heure"
                          value="HEURE"
                        ></v-radio>
                        <v-radio
                          label="Prix par jour"
                          value="JOUR"
                        ></v-radio>
                        <v-radio
                          label="Prix total"
                          value="TOTAL"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        class="mt-0 purple-input input-budget no-arrows"
                        v-model="formation.budgets.find(budget => budget.client.id === formation.client.id).cout"
                        type="number"
                        step=".01"
                        suffix="€"
                        @input="setBudgetTotalIntraSstrt($event)"
                        @blur="updateBudgets()"
                      >
                      </v-text-field>
                    </td>
                    <td>
                      {{ toEuro(getCoutsPedagogiquesIntraSstrt()) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row v-if="['INTRA', 'SSTRT'].includes(formation.type) && !formation.client">
                <i>Veuillez sélectionner un client afin de renseigner le budget</i>
              </v-row>
            </v-card-text>
            <h4>Autres recettes</h4>
            <v-card-text>
              <v-simple-table
                id="table-recettes"
              >
                <thead>
                  <tr>
                    <th>Client à refacturer</th>
                    <th>Description</th>
                    <th>Quantité</th>
                    <th>Coût unitaire HT</th>
                    <th>Total</th>
                    <th>Supprimer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="i of indexRecettes"
                    :key="i"
                  >
                    <td>
                      <v-autocomplete
                        outlined
                        dense
                        v-model="formation.recettes[i-1].client"
                        :items="clients"
                        hint="Laisser vide si aucun client associé"
                        persistent-hint
                        item-text="nomCommercial"
                        item-value="id"
                        clearable
                        return-object
                        @blur="updateRecettes(i-1)"
                      />
                    </td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        v-model="formation.recettes[i-1].libelle"
                        @blur="updateRecettes(i-1)"
                      />
                    </td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        v-model="formation.recettes[i-1].unites"
                        class="mt-0 purple-input input-budget no-arrows"
                        type="number"
                        @blur="updateRecettes(i-1)"
                      />
                    </td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        v-model="formation.recettes[i-1].prixUnitaire"
                        class="mt-0 purple-input input-budget no-arrows"
                        type="number"
                        step=".01"
                        suffix="€"
                        @blur="updateRecettes(i-1)"
                      />
                    </td>
                    <td>{{ toEuro(formation.recettes[i-1].unites * formation.recettes[i-1].prixUnitaire) }}</td>
                    <td>
                      <v-icon
                        @click="deleteRecette(i-1)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
                <v-btn
                  @click="addLigneRecette()"
                >
                  Ajouter une recette
                </v-btn>
                <tfoot
                  class="table-budget-total"
                >
                  <th
                    colspan="4"
                  >
                    Total autres recettes
                  </th>
                  <td>
                    {{ toEuro(getTotalRecettes()) }} HT
                  </td>
                </tfoot>
              </v-simple-table>
            </v-card-text>
            <h3>
              Total recettes : {{ toEuro(Number(getCoutsPedagogiques()) + Number(getTotalRecettes())) }}
            </h3>
          </v-card>
          <!--
          <v-card>
            <v-card-title>
              Dépenses
            </v-card-title>
            <v-card-text>
              ...
            </v-card-text>
          </v-card>
          -->
        </v-tab-item>
        <v-tab-item
          :key="3"
        >
          <v-row>
            <v-col
              cols="12"
            >

              <v-expansion-panels>
                <v-expansion-panel
                  v-if="formation.type !== 'SSTRT'"
                >
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>
                      Conventions de formation
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(client,) in clients"
                            :key="client.id"
                          >
                            <td>
                              Convention de formation {{ client.raisonSociale }}
                              <span v-if="client.nomCommercial !== client.raisonSociale">({{ client.nomCommercial }})</span>
                            </td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadConventionDocx(client)"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadConventionPdf(client)"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('CONVENTION', { formation: formation, client: client })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
                      Programme de cours
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="formation.cours">
                            <td>Programme de cours {{ formation.cours.name }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadProgrammeDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadProgrammePdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              ...
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-bell-ring</v-icon>
                      Convocations
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="formation.stagiaires.length > 0">
                            <td>Convocations (tous stagiaires)</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                          <tr
                            v-for="(stagiaire,) in formation.stagiaires"
                            :key="stagiaire.id"
                          >
                            <td>Convocation {{ stagiaire.nom }} {{ stagiaire.prenom }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('CONVOCATION', { formation: formation, stagiaire: stagiaire })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-head-snowflake-outline</v-icon>
                      Auto-positionnements
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="formation.stagiaires.length > 0">
                            <td>Auto-positionnements (tous stagiaires)</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAutoPositionnementDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAutoPositionnementPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                          <tr
                            v-for="(stagiaire,) in formation.stagiaires"
                            :key="stagiaire.id"
                          >
                            <td>Auto-positionnement {{ stagiaire.nom }} {{ stagiaire.prenom }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAutoPositionnementStagiaireDocx(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAutoPositionnementStagiairePdf(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('AUTOPOS', { formation: formation, stagiaire: stagiaire })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td><i>Modèle d'auto-positionnement vierge</i></td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-draw-pen</v-icon>
                      Feuilles d'émargement
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Feuille d'émargement (par semaine)</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadEmargementDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadEmargementPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="formation.type !== 'SSTRT' && ['TERMINE', 'ENCOURS'].includes(formation.etat)"
                >
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-emoticon-outline</v-icon>
                      Questionnaires de satisfaction
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="formation.stagiaires.length > 0">
                            <td>Questionnaires de satisfaction (tous stagiaires)</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                          <tr
                            v-for="(stagiaire,) in formation.stagiaires"
                            :key="stagiaire.id"
                          >
                            <td>Questionnaire de satisfaction {{ stagiaire.nom }} {{ stagiaire.prenom }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionStagiaireDocx(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionStagiairePdf(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('SATISFACTION', { formation: formation, stagiaire: stagiaire })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td><i>Modèle de questionnaire de satisfaction vierge</i></td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadSatisfactionPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="formation.type !== 'SSTRT' && ['TERMINE', 'ENCOURS'].includes(formation.etat)"
                >
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-list-status</v-icon>
                      Évaluation des compétences des stagiaires
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(formateur,) in formation.formateurs"
                            :key="formateur.id"
                          >
                            <td>Évaluations des stagiaires par {{ formateur.nom }} {{ formateur.prenom }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadEvaluationDocx(formateur)"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadEvaluationPdf(formateur)"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('EVALUATION', { formation: formation, formateur: formateur })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="formation.type !== 'SSTRT' && ['TERMINE', 'ENCOURS'].includes(formation.etat)"
                >
                  <v-expansion-panel-header class="display-1">
                    <span>
                      <v-icon class="mr-2">mdi-certificate-outline</v-icon>
                      Attestations de formation
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Document
                            </th>
                            <th class="text-center">
                              Télécharger
                            </th>
                            <th class="text-center">
                              Envoyer par e-mail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="formation.stagiaires.length > 0">
                            <td>Attestations de formation (tous stagiaires)</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAttestationDocx()"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAttestationPdf()"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center"></td>
                          </tr>
                          <tr
                            v-for="(stagiaire,) in formation.stagiaires"
                            :key="stagiaire.id"
                          >
                            <td>Attestation de formation {{ stagiaire.nom }} {{ stagiaire.prenom }}</td>
                            <td class="text-center">
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#2a63b9"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAttestationStagiaireDocx(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-word
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format DOCX</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                open-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-1"
                                    small
                                    color="#c74f46"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadAttestationStagiairePdf(stagiaire)"
                                  >
                                    <v-icon>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Télécharger au format PDF</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center">
                              <v-btn
                                small
                                color="primary"
                                @click="$refs.dialogNewEmailRef.show('ATTESTATION', { formation: formation, stagiaire: stagiaire })"
                              >
                                <v-icon>
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          :key="4"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-data-table
                class="table-factures"
                :headers="headersFactures"
                :items="formation.factures"
                :items-per-page="5"
                @click:row="detailFacture"
              >
                <template v-slot:[`item.etat`]="{ item }">
                  <div v-html="displayEtatFacture(item.etat)"></div>
                </template>
                <template v-slot:[`item.dateEmission`]="{ item }">
                  {{ new Date(item.dateEmission).toLocaleString().substring(0, 10) }}
                </template>
                <template v-slot:[`item.montantHT`]="{ item }">
                  {{ toEuro(item.montantHT) }}
                </template>
                <template v-slot:[`item.montantTVA`]="{ item }">
                  {{ toEuro(item.montantTVA) }}
                </template>
                <template v-slot:[`item.montantTotal`]="{ item }">
                  {{ toEuro(item.montantTotal) }}
                </template>
                <template v-slot:[`item.lienFacture`]="{ item }">
                  <v-icon
                    v-if="item.lienFacture"
                    @click.stop="downloadFacture(item.reference)"
                  >
                    mdi-download
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              @click="$router.push({ name: 'CreerFacture' , params: { formationId: formation.id } })"
            >
              <v-icon class="mr-2">mdi-file-document-plus-outline</v-icon>
              Créer une facture à partir de cette formation
            </v-btn>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
    <template>
      <v-dialog
        v-model="dialogAnnul"
        max-width="500px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Souhaitez-vous annuler cette formation ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="dialogAnnul = false"
            >
              Non
            </v-btn>
            <v-btn
              color="primary"
              @click="annulerFormation"
            >
              Oui
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-model="dialogSupprimer"
        max-width="560px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Souhaitez-vous supprimer cette formation ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="dialogSupprimer = false"
            >
              Non
            </v-btn>
            <v-btn
              color="primary"
              @click="supprimerFormation()"
            >
              Oui
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <dialog-new-email
      ref="dialogNewEmailRef"
    />
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';
  import slugify from 'slugify';

  export default {

    components: {
      DialogNewEmail: () => import('../../component/DialogNewEmail'),
    },

    data() {
      return {
        speakersPhotosUrl: process.env.VUE_APP_SPEAKERS_PHOTOS_BASE_URL,
        formNewStagiaire: false,
        formExistingStagiaire: false,
        isLoading: false,
        searchExistingStagiaire: '',
        headersStagiaires: [
          {
            sortable: false,
            text: 'Titre',
            value: 'sexe',
          },
          {
            sortable: true,
            text: 'Nom',
            value: 'nom',
          },
          {
            sortable: false,
            text: 'Prénom',
            value: 'prenom',
          },
          {
            sortable: false,
            text: 'E-mail',
            value: 'email',
          },
          {
            sortable: true,
            text: 'Statut',
            value: 'type',
          },
          {
            sortable: true,
            text: 'Société',
            value: 'societe.nomCommercial',
          },
          {
            sortable: false,
            text: 'Actions',
            value: 'actions',
          },
        ],
        titreStagiairesItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
        typeStagiairesItems: [
          {
            type: 'SALARIE',
            intitule: 'Salarié d\'employeur privé',
          },
          {
            type: 'PARTICULIER',
            intitule: 'Particulier à ses propres frais',
          },
          {
            type: 'SANSEMPLOI',
            intitule: 'Personne en recherche d\'emploi',
          },
          {
            type: 'APPRENTI',
            intitule: 'Apprenti',
          },
          {
            type: 'AUTRE',
            intitule: 'Autre profil',
          },
        ],
        headersCreneaux: [
          {
            sortable: false,
            text: 'Intitulé',
            value: 'intitule',
          },
          {
            sortable: false,
            text: 'Date',
            value: 'date',
          },
          {
            sortable: false,
            text: 'Début',
            value: 'heureDebut',
          },
          {
            sortable: false,
            text: 'Fin',
            value: 'heureFin',
          },
          {
            sortable: false,
            text: 'Formateur',
            value: 'formateur',
          },
        ],
        headersFactures: [
          {
            sortable: true,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: true,
            text: 'Client',
            value: 'client.nomCommercial',
          },
          {
            sortable: true,
            text: 'Destinataire',
            value: 'destinataire.nomCommercial',
          },
          {
            sortable: true,
            text: 'Date d\'émission',
            value: 'dateEmission',
          },
          {
            sortable: false,
            text: 'État',
            value: 'etat',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Montant HT',
            value: 'montantHT',
            align: 'right',
          },
          {
            sortable: false,
            text: 'TVA',
            value: 'montantTVA',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Total TTC',
            value: 'montantTotal',
            align: 'right',
          },
        ],
        items: [],
        formation: {
          cours: {
            id: 0,
          },
          dureeJours: 0,
          dureeHeures: 0,
          adresse: {},
          recettes: [],
          depenses: [],
        },
        stagiaire: {
          nom: '',
          prenom: '',
          sexe: null,
          dateNaissance: '',
          adresse: {},
          email: '',
          telephone: '',
          societe: {
            id: null,
          },
        },
        nbStagiairesAnonymes: 1,
        allStagiaires: [],
        formateurs: [],
        clients: [],
        allClients: [],
        dateDebutFormatted: '',
        dateFinFormatted: '',
        dialogAnnul: false,
        dialogSupprimer: false,
        confirmerDisabled: false,
        convocationsDisabled: false,
        fermerDisabled: false,
        annulerDisabled: false,
        genererEmargementDisabled: false,
        downloadEmargementDisabled: false,
        genererAttestationsDisabled: false,
        downloadAttestationsDisabled: false,
        sendAttestationsDisabled: false,
        commentairesSeeMore: false,
        tabs: 0,
        displayEtat: '',
        displayType: '',
        modeCalculBudget: '',
        displayModeCalcul: '',
        budgetTotalIntraSstrt: 0,
        indexRecettes: 0,
        indexDepenses: 0,
      }
    },

    created () {
      const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Europe/Paris' };

      this.axios.get('/formations/' + this.$route.params.id)
        .then((res) => {
          this.formation = res.data;

          this.formation.creneaux.sort(function(a, b) {
            if (new Date(a.date + ' ' + a.heureDebut) > new Date(b.date + ' ' + b.heureDebut)) return 1;
            if (new Date(a.date + ' ' + a.heureDebut) < new Date(b.date + ' ' + b.heureDebut)) return -1;
          });

          this.dateDebutFormatted = new Date(this.formation.dateDebut).toLocaleString('fr-FR', dateOptions);
          this.dateFinFormatted = new Date(this.formation.dateFin).toLocaleString('fr-FR', dateOptions);

          if (this.formation.type === 'INTER') {
            // Si INTER : récupérer les clients en fonction des stagiaires + éliminer les doublons
            this.clients = this.getClientsFromStagiaires();
          } else if (['INTRA', 'SSTRT'].includes(this.formation.type) && this.formation.client) {
            // Si INTRA ou SSTRT : un seul client dans la liste (pour la gestion des docs c'est plus simple)
            this.clients.push(this.formation.client);
          }

          // Récupérer les formateurs en fonction des créneaux + supprimer les null et éliminer les doublons
          this.formateurs = this.formation.creneaux.map(creneau => creneau.formateur).filter(n => n).filter((value, index, self) => { return index === self.findIndex(t => t.id === value.id); },
          );

          // Pour les INTRA et SSTRT : base de calcul pour le budget (heure/jour/total)
          if (this.formation.client && ['INTRA', 'SSTRT'].includes(this.formation.type)) {
            this.modeCalculBudget = this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).modeCalcul;
            this.budgetTotalIntraSstrt = this.getInitBudgetTotalIntraSstrt();
          }

          // MAJ de l'index des recettes et dépenses si cela a déjà été saisi
          this.indexRecettes = this.formation.recettes.length;
          this.indexDepenses = this.formation.depenses.length;

          switch (this.formation.etat) {
            case 'OUVERT':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-blue">Ouverte</span>';
              break;
            case 'CONFIRME':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-green">Confirmée</span>';
              break;
            case 'ENCOURS':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-indigo">En cours</span>';
              break;
            case 'TERMINE':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-purple">Terminée</span>';
              break;
            case 'FERME':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-red">Fermée</span>';
              break;
            case 'ANNULE':
              this.displayEtat = '<span id="formation-etat" class="badge-chips badge-red">Annulée</span>';
              break;
          }

          switch (this.formation.type) {
            case 'INTER':
              this.displayType = '<span id="formation-type" class="badge-chips badge-blue-outline">Inter-entreprises</span>';
              break;
            case 'INTRA':
              this.displayType = '<span id="formation-type" class="badge-chips badge-yellow-outline">Intra-entreprise</span>';
              break;
            case 'SSTRT':
              this.displayType = '<span id="formation-type" class="badge-chips badge-gray-outline">Sous-traitée</span>';
              break;
          }

          if (this.formation.type !== 'SSTRT' && (this.formation.etat === 'CONFIRME')) {
            this.headersStagiaires.push({
              sortable: false,
              text: 'Convoquer',
              value: 'convoquer',
              align: 'center',
            });
          }

          if (this.formation.type === 'INTRA' && this.formation.client) {
            // Si formation INTRA : "bloquer" le type de stagiaire à SALARIE
            this.stagiaire.type = 'SALARIE';
            this.stagiaire.societe = this.formation.client;
            // Si formation INTRA : récupérer uniquement les stagiaires non null de la société cliente
            return this.axios.get('/stagiaires/societe/' + this.formation.client.id + '/notNull');
          } else {
            // Si formation INTER ou SSTRT : récupérer les stagiaires non null
            return this.axios.get('/stagiaires/notNull');
          }
        })
        .then((res) => {
          // Conserver dans la liste de stagiaires uniquement les stagiaires qui ne participent pas déjà à la formation
          this.allStagiaires = res.data.filter(s => !this.formation.stagiaires.map(s2 => s2.id).includes(s.id));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération de la formation', type: 'warn' });
        });

      this.axios.get('/clients/direct')
        .then((res) => {
          this.allClients = res.data;
          this.allClients.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });
    },

    methods: {
      goBackFormations() {
        this.$router.push({ name: 'Formations', params: { year: new Date().getFullYear() } });
      },

      validate() {
        if (this.$refs.stagiaire.validate()) {
          if (this.stagiaire.id) {
            // edit mode
            this.updateStagiaire();
          } else {
            // create mode
            this.createStagiaire();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createStagiaire() {
        const tmpFormation = Object.assign({}, this.formation);
        const tmpStagiaire = Object.assign({}, this.stagiaire);
        tmpStagiaire.adresse = Object.assign({}, this.stagiaire.adresse);

        tmpFormation.stagiaires.push(tmpStagiaire);

        // Si le client est un particulier
        if (['PARTICULIER', 'SANSEMPLOI', 'AUTRE'].includes(this.stagiaire.type) && !this.stagiaire.societe.id) {
          const newClient = {
            raisonSociale: this.stagiaire.nom + ' ' + this.stagiaire.prenom,
            codeFacture: 'PAR',
            adresse: this.stagiaire.adresse,
            email: this.stagiaire.email,
            telephone: this.stagiaire.telephone,
            type: 'PARTICULIER',
            nature: 'CLIENT',
          };
          this.axios.post('/clients', newClient)
            .then(res => {
              tmpStagiaire.societe = res.data;
              this.addStagiaire(tmpStagiaire);
            });
        } else {
          this.addStagiaire(tmpStagiaire);
        }
        this.$refs.stagiaire.reset();
        this.stagiaire.adresse = {};
      },

      showFormNewStagiaire() {
        this.formNewStagiaire = true;
        // Scroller vers le formulaire
        this.$nextTick(() => {
          const element = document.getElementById('form-new-stagiaire');
          element.scrollIntoView({ behavior: 'smooth' });
        });
      },

      showFormExistingStagiaire() {
        this.formExistingStagiaire = true;
        // Scroller vers le formulaire
        this.$nextTick(() => {
          const element = document.getElementById('form-existing-stagiaire');
          element.scrollIntoView({ behavior: 'smooth' });
        });
      },

      addExistingStagiaire() {
        const tmpFormation = Object.assign({}, this.formation);
        const tmpStagiaire = Object.assign({}, this.stagiaire);
        tmpStagiaire.adresse = Object.assign({}, this.stagiaire.adresse);
        tmpFormation.stagiaires.push(tmpStagiaire);
        this.addStagiaire(tmpStagiaire);
        this.$refs.stagiaireExistant.reset();
      },

      addStagiaire(stagiaire) {
        this.axios.patch('/formations/' + this.formation.id + '/addStagiaire', stagiaire)
          .then(res => {
            if (stagiaire.sexe === 'f') {
              this.setSnack({ text: 'La stagiaire a bien été ajoutée à la session de formation', type: 'success' });
            } else {
              this.setSnack({ text: 'Le stagiaire a bien été ajouté à la session de formation', type: 'success' });
            }

            this.formation = res.data;
          })
          .then((res) => {
            if (this.formation.type === 'INTRA' && this.formation.client) {
              this.stagiaire.type = 'SALARIE';
              this.stagiaire.societe = this.formation.client;
              this.$forceUpdate();
            }

            return res;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'ajout du stagiaire', type: 'warn' });
          });
      },

      addStagiairesAnonymes() {
        const stagiaires = [];
        for (let i = 0; i < this.nbStagiairesAnonymes; i++) {
          const stagiaire = {
            nom: null,
            prenom: null,
            sexe: null,
            dateNaissance: null,
            adresse: null,
            email: null,
            telephone: null,
            type: 'SALARIE',
            signature: null,
          };
          if (this.formation.type === 'INTRA') {
            stagiaire.societe = this.formation.client;
          } else {
            stagiaire.societe = null;
          }
          stagiaires.push(stagiaire);
        }
        this.axios.patch('/formations/' + this.formation.id + '/addStagiaires', stagiaires)
          .then(res => {
            this.setSnack({ text: 'Le nombre de participants à la session de formation a été mis à jour', type: 'success' });
            this.formation = res.data;
            return this.success;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour de la formation', type: 'warn' });
            this.fail = true;
          })

        this.formation.nbStagiaires = Number(this.formation.nbStagiaires) + Number(this.nbStagiairesAnonymes);
        this.nbStagiairesAnonymes = 1;
        this.$forceUpdate();
      },

      editStagiaire(item) {
        this.stagiaire = { ...item };
        if (!this.stagiaire.adresse) {
          this.stagiaire.adresse = {};
        }
        this.formNewStagiaire = true;

        // Scroller vers le formulaire
        this.$nextTick(() => {
          const element = document.getElementById('form-new-stagiaire');
          element.scrollIntoView({ behavior: 'smooth' });
        });
      },

      async updateStagiaire() {
        // Si le stagiaire est un particulier, sans emploi, ou autre ...
        if (['PARTICULIER', 'SANSEMPLOI', 'AUTRE'].includes(this.stagiaire.type)) {
          // Si le stagiaire n'avait pas de société : créer sa "société"
          if (!this.stagiaire.societe) {
            const newClient = {
              raisonSociale: this.stagiaire.nom + ' ' + this.stagiaire.prenom,
              codeFacture: 'PAR',
              adresse: this.stagiaire.adresse,
              email: this.stagiaire.email,
              telephone: this.stagiaire.telephone,
              type: this.stagiaire.type,
              nature: 'CLIENT',
            };
            await this.axios.post('/clients', newClient).then(res => { this.stagiaire.societe = res.data });
          } else {
            // S'il en avait déjà une : mettre à jour les informations du "client" associé
            const client = {
              raisonSociale: this.stagiaire.nom + ' ' + this.stagiaire.prenom,
              codeFacture: 'PAR',
              adresse: this.stagiaire.adresse,
              email: this.stagiaire.email,
              telephone: this.stagiaire.telephone,
              type: this.stagiaire.type,
              nature: 'CLIENT',
            };
            await this.axios.put('/clients/' + this.stagiaire.societe.id, client).then(res => { this.stagiaire.societe = res.data });
          }
        }

        // Mise à jour effective du stagiaire
        this.axios.put('/stagiaires/' + this.stagiaire.id, this.stagiaire)
          .then((res) => {
            if (this.stagiaire.sexe === 'f') {
              this.setSnack({ text: 'La stagiaire a bien été mise à jour', type: 'success' });
            } else {
              this.setSnack({ text: 'Le stagiaire a bien été mis à jour', type: 'success' });
            }
            this.$refs.stagiaire.reset();
            this.stagiaire.adresse = {};
            this.formNewStagiaire = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour du stagiaire', type: 'warn' });
          })

        // (En INTER uniquement) si on a changé la société du client, et que celle-ci n'avait pas de budget associé, le créer et mettre à jour la forma
        if (this.formation.type === 'INTER' && !this.clients.map(c => c.id).includes(this.stagiaire.societe.id)) {
          const budget = {
            client: this.stagiaire.societe,
            cout: this.formation.cours.price,
            modeCalcul: 'STAGIAIRE',
          };
          this.axios.patch('/formations/' + this.formation.id + '/addBudget', budget);
          this.clients = this.getClientsFromStagiaires();
        }

        // MAJ sans avoir besoin de reload
        const tmpFormation = Object.assign({}, this.formation);
        Object.assign(tmpFormation.stagiaires[this.formation.stagiaires.indexOf(this.formation.stagiaires.find(s => s.id === this.stagiaire.id))], { ...this.stagiaire });
      },

      deleteStagiaire(item) {
        const tmpFormation = Object.assign({}, this.formation);
        tmpFormation.stagiaires.splice(tmpFormation.stagiaires.indexOf(item), 1);

        this.axios.patch('/formations/' + this.formation.id + '/removeStagiaire', item)
          .then((res) => {
            if (item.sexe === 'f') {
              this.setSnack({ text: 'La stagiaire a bien été retirée de la session de formation', type: 'success' });
            } else {
              this.setSnack({ text: 'Le stagiaire a bien été retiré de la session de formation', type: 'success' });
            }
            this.formation.nbStagiaires--;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du retrait du stagiaire', type: 'warn' });
          });
      },

      detailFacture(item, row) {
        this.$router.push({ name: 'FactureDetail', params: { id: item.id } });
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      formatAdresse() {
        const adresse = this.formation.adresse;

        if (!this.formation.adresse && this.formation.distanciel) {
          return 'Classe virtuelle';
        }

        let formattedAdresse = '';
        formattedAdresse += adresse.adressePostale ? adresse.adressePostale + '<br>' : '';
        formattedAdresse += adresse.codePostal ? adresse.codePostal + ' ' : '';
        formattedAdresse += adresse.ville ?? '';
        formattedAdresse += adresse.pays ? ', ' + adresse.pays + ' ' : '';
        return formattedAdresse;
      },

      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      displayCommentaires() {
        if (this.formation.commentaires.length <= 140 || this.commentairesSeeMore) {
          return this.formation.commentaires;
        } else {
          return this.formation.commentaires.substring(0, 140) + '...';
        }
      },

      getClientsFromStagiaires() {
        // flatMap permet de supprimer du tableau les stagiaires qui n'ont pas pas de société
        return this.formation.stagiaires.flatMap(stagiaire => {
          if (stagiaire.societe) {
            return [stagiaire.societe];
          } else {
            return [];
          }
        }).filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          )),
        );
      },

      getBudgetOfClient(clientId) {
        return this.formation.budgets.find(budget => budget.client.id === clientId).cout;
      },

      getStagiairesOfClient(clientId) {
        return this.formation.stagiaires.filter(stagiaire => stagiaire.societe && stagiaire.societe.id === clientId);
      },

      getCoutsPedagogiquesInter() {
        let couts = 0;
        for (const budget of this.formation.budgets) {
          couts += this.getBudgetOfClient(budget.client.id) * this.getStagiairesOfClient(budget.client.id).length;
        }
        return couts;
      },

      getCoutsPedagogiquesIntraSstrt() {
        const budget = this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout;
        if (this.modeCalculBudget === 'TOTAL') {
          return budget;
        }
        if (this.modeCalculBudget === 'JOUR') {
          return (budget) * this.formation.dureeJours;
        }
        if (this.modeCalculBudget === 'HEURE') {
          return budget * this.formation.dureeHeures;
        }
      },

      getCoutsPedagogiques() {
        if (this.formation.type === 'INTER') {
          return this.getCoutsPedagogiquesInter();
        } else if ((this.formation.type === 'INTRA' || this.formation.type === 'SSTRT') && this.formation.client) {
          return this.getCoutsPedagogiquesIntraSstrt();
        }
        return 0;
      },

      getTotalRecettes() {
        let total = 0;
        for (const recette of this.formation.recettes) {
          total += recette.unites * recette.prixUnitaire;
        }
        return total;
      },

      setBudgetModeCalcul() {
        this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).modeCalcul = this.modeCalculBudget;

        if (this.modeCalculBudget === 'TOTAL') {
          this.displayModeCalcul = 'Prix total';
          this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout = this.budgetTotalIntraSstrt;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.displayModeCalcul = 'Prix par jour';
          this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout = this.budgetTotalIntraSstrt / this.formation.dureeJours;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.displayModeCalcul = 'Prix par heure';
          this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout = this.budgetTotalIntraSstrt / this.formation.dureeHeures;
        }
      },

      getInitBudgetTotalIntraSstrt() {
        if (this.modeCalculBudget === 'TOTAL') {
          this.displayModeCalcul = 'Prix total';
          return this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.displayModeCalcul = 'Prix par jour';
          return this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout * this.formation.dureeJours;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.displayModeCalcul = 'Prix par heure';
          return this.formation.budgets.find(budget => budget.client.id === this.formation.client.id).cout * this.formation.dureeHeures;
        }
      },

      setBudgetTotalIntraSstrt(value) {
        if (this.modeCalculBudget === 'TOTAL') {
          this.budgetTotalIntraSstrt = value;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.budgetTotalIntraSstrt = value * this.formation.dureeJours;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.budgetTotalIntraSstrt = value * this.formation.dureeHeures;
        }
      },

      updateBudgets() {
        this.axios.patch('/formations/' + this.$route.params.id + '/updateBudgets', this.formation.budgets);
      },

      addLigneRecette() {
        this.formation.recettes.push({});
        this.indexRecettes++;
      },

      addLigneDepense() {
        this.formation.depenses.push({});
        this.indexDepenses++;
      },

      updateRecettes(index) {
        if (this.formation.recettes[index].libelle && this.formation.recettes[index].unites && this.formation.recettes[index].prixUnitaire) {
          this.axios.patch('/formations/' + this.formation.id + '/updateRecettes', this.formation.recettes);
        }
      },

      deleteRecette(index) {
        this.indexRecettes--;
        this.formation.recettes.splice(index, 1);
        this.axios.patch('/formations/' + this.formation.id + '/updateRecettes', this.formation.recettes);
      },

      displayEtatFacture(etat) {
        switch (etat) {
          case 'NOUVEAU':
            return '<span class="badge-chips-small badge-gray">En att. d\'envoi</span>';
          case 'ENVOYE':
            return '<span class="badge-chips-small badge-blue">En attente</span>';
          case 'ACQUITTE':
            return '<span class="badge-chips-small badge-green-2">Payée</span>';
          case 'RETARD':
            return '<span class="badge-chips-small badge-orange">En retard</span>';
        }
      },

      confirmerFormation() {
        this.confirmerDisabled = true;
        this.axios.get('/formations/' + this.$route.params.id + '/confirmer')
          .then((res) => {
            this.formation.etat = 'CONFIRME';
            this.displayEtat = '<span id="formation-etat" class="badge-chips badge-green">CONFIRMÉE</span>';
            this.$forceUpdate();
            this.setSnack({ text: 'Formation confirmée', type: 'success' });
            this.confirmerDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la confirmation de la formation', type: 'warn' });
            this.confirmerDisabled = true;
          });
      },

      convoquer() {
        this.convocationsDisabled = true;
        this.setSnack({ text: 'Envoi des mails de convocation en cours ...', type: 'success' });

        this.axios.get('/formations/' + this.$route.params.id + '/convoquer')
          .then((res) => {
            this.formation.convocationsEnvoyees = res.data.convocationsEnvoyees;
            this.$forceUpdate();
            this.setSnack({ text: 'Les mails de convocation ont été envoyés', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi des convocations de la formation', type: 'warn' });
            this.convocationsDisabled = false;
          });
      },

      convoquerIndiv(stagiaire) {
        this.setSnack({ text: 'Envoi du mail de convocation en cours ...', type: 'success' });

        this.axios.post('/formations/' + this.$route.params.id + '/sendMailConvocationStagiaires/' + stagiaire.id)
          .then((res) => {
            this.setSnack({ text: 'Le mail de convocation a été envoyé', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi de la convocation', type: 'warn' });
          });
      },

      downloadConventionDocx(client) {
        this.axios.get('/documents/convention/' + this.formation.id + '/docx?client=' + client.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/docx' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Convention_' + this.formation.reference + '_' + slugify(client.raisonSociale) + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la convention de formation', type: 'warn' });
          });
      },

      downloadConventionPdf(client) {
        this.axios.get('/documents/convention/' + this.formation.id + '/pdf?client=' + client.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Convention_' + this.formation.reference + '_' + slugify(client.raisonSociale) + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la convention de formation', type: 'warn' });
          });
      },

      downloadProgrammeDocx() {
        this.downloadDocxDisabled = true;
        this.axios.get('/documents/programme/' + this.formation.cours.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/docx' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Programme_' + this.formation.cours.reference + '.docx');
            document.body.appendChild(link);
            link.click();
            this.downloadDocxDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du programme de cours', type: 'warn' });
            this.downloadDocxDisabled = false;
          });
      },

      downloadProgrammePdf() {
        this.downloadPdfDisabled = true;
        this.axios.get('/documents/programme/' + this.formation.cours.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Programme_' + this.formation.cours.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadPdfDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du programme de cours', type: 'warn' });
            this.downloadPdfDisabled = false;
          });
      },

      downloadEmargementDocx() {
        this.axios.get('/documents/emargement/' + this.formation.id + '/semaine/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/docx' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'FeuilleEmargement_' + this.formation.reference + '_semaine.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la feuille d\'émargement', type: 'warn' });
          });
      },

      downloadEmargementPdf() {
        this.axios.get('/documents/emargement/' + this.formation.id + '/semaine/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'FeuilleEmargement_' + this.formation.reference + '_semaine.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la feuille d\'émargement', type: 'warn' });
          });
      },

      downloadSatisfactionDocx() {
        this.axios.get('/documents/satisfaction/' + this.formation.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Questionnaires_satisfaction_' + this.formation.reference + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du questionnaire de satisfaction', type: 'warn' });
          });
      },

      downloadSatisfactionPdf() {
        this.axios.get('/documents/satisfaction/' + this.formation.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Questionnaires_satisfaction_' + this.formation.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du questionnaire de satisfaction', type: 'warn' });
          });
      },

      downloadSatisfactionStagiaireDocx(stagiaire) {
        this.axios.get('/documents/satisfaction/' + this.formation.id + '/docx?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Questionnaire_satisfaction_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du questionnaire de satisfaction', type: 'warn' });
          });
      },

      downloadSatisfactionStagiairePdf(stagiaire) {
        this.axios.get('/documents/satisfaction/' + this.formation.id + '/pdf?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Questionnaire_satisfaction_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du questionnaire de satisfaction', type: 'warn' });
          });
      },

      downloadAutoPositionnementDocx() {
        this.axios.get('/documents/autopos/' + this.formation.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Autopositionnements_' + this.formation.reference + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'autopositionnement', type: 'warn' });
          });
      },

      downloadAutoPositionnementPdf() {
        this.axios.get('/documents/autopos/' + this.formation.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Autopositionnements_' + this.formation.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'autopositionnement', type: 'warn' });
          });
      },

      downloadAutoPositionnementStagiaireDocx(stagiaire) {
        this.axios.get('/documents/autopos/' + this.formation.id + '/docx?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Autopositionnement_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'autopositionnement', type: 'warn' });
          });
      },

      downloadAutoPositionnementStagiairePdf(stagiaire) {
        this.axios.get('/documents/autopos/' + this.formation.id + '/pdf?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Autopositionnement_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'autopositionnement', type: 'warn' });
          });
      },

      downloadAttestationDocx() {
        this.axios.get('/documents/attestation/' + this.formation.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attestations_' + this.formation.reference + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'attestation de formation', type: 'warn' });
          });
      },

      downloadAttestationPdf() {
        this.axios.get('/documents/attestation/' + this.formation.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attestations_' + this.formation.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'attestation de formation', type: 'warn' });
          });
      },

      downloadAttestationStagiaireDocx(stagiaire) {
        this.axios.get('/documents/attestation/' + this.formation.id + '/docx?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attestation_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'attestation de formation', type: 'warn' });
          });
      },

      downloadAttestationStagiairePdf(stagiaire) {
        this.axios.get('/documents/satisfaction/' + this.formation.id + '/pdf?stagiaire=' + stagiaire.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attestation_' + this.formation.reference + '_' + slugify(stagiaire.nom) + '_' + slugify(stagiaire.prenom) + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'attestation de formation', type: 'warn' });
          });
      },

      downloadEvaluationDocx(formateur) {
        this.axios.get('/documents/evaluation/' + this.formation.id + '/docx?formateur=' + formateur.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Evaluation_stagiaires_' + this.formation.reference + '_' + slugify(formateur.nom) + '_' + slugify(formateur.prenom) + '.docx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'évaluation des compétences des stagiaires par le formateur', type: 'warn' });
          });
      },

      downloadEvaluationPdf(formateur) {
        this.axios.get('/documents/evaluation/' + this.formation.id + '/pdf?formateur=' + formateur.id, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Evaluation_stagiaires_' + this.formation.reference + '_' + slugify(formateur.nom) + '_' + slugify(formateur.prenom) + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'évaluation des compétences des stagiaires par le formateur', type: 'warn' });
          });
      },

      downloadFacture(reference) {
        this.axios.get('/facturesSortantes/' + reference + '/downloadPDF', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reference + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadEmargementDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la facture', type: 'warn' });
            this.downloadEmargementDisabled = false;
          });
      },

      downloadAttestations() {
        this.downloadAttestationsDisabled = true;
        this.setSnack({ text: 'Téléchargement des attestations de formation en cours ...', type: 'success' });

        this.axios.get('/formations/' + this.$route.params.id + '/downloadAttestationsPDF', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attestations_' + this.formation.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement des attestations de formation', type: 'warn' });
            this.downloadAttestationsDisabled = false;
          });
      },

      sendAttestations() {
        this.sendAttestationsDisabled = true;
        this.setSnack({ text: 'Envoi des attestations en cours ...', type: 'success' });

        this.axios.post('/formations/' + this.$route.params.id + '/sendMailAttestations')
          .then((res) => {
            this.formation.attestationsEnvoyees = res.data.attestationsEnvoyees;
            this.$forceUpdate();
            this.setSnack({ text: 'Les attestations de formation ont été envoyées', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi des attestations de formation', type: 'warn' });
            this.sendAttestationsDisabled = false;
          });
      },

      fermerFormation() {
        this.fermerDisabled = true;
        this.axios.get('/formations/' + this.$route.params.id + '/fermer')
          .then((res) => {
            this.formation.etat = 'FERME';
            this.items[0] = {
              icon: 'mdi-traffic-light',
              title: 'État',
              value: '<span id="formation-etat" class="badge-chips badge-red">FERMÉE</span>',
            };
            this.$forceUpdate();
            this.setSnack({ text: 'Formation fermée', type: 'success' });
            this.fermerDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la fermeture de la formation', type: 'warn' });
            this.fermerDisabled = false;
          });
      },

      annulerFormation() {
        this.dialogAnnul = false;
        this.annulerDisabled = true;
        this.axios.get('/formations/' + this.formation.id + '/annuler')
          .then((res) => {
            this.formation.etat = 'ANNULE';
            this.items[0] = {
              icon: 'mdi-traffic-light',
              title: 'État',
              value: '<span id="formation-etat" class="badge-chips badge-red">ANNULÉE</span>',
            };
            this.$forceUpdate();
            this.setSnack({ text: 'Formation annulée', type: 'success' });
            this.annulerDisabledEnCours = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'annulation de la formation', type: 'warn' });
            this.annulerDisabledEnCours = false;
          });
      },

      supprimerFormation() {
        this.dialogSupprimer = false;
        this.axios.delete('/formations/' + this.formation.id)
          .then((res) => {
            this.$router.push({ name: 'Formations', hash: '#fermees' })
            this.setSnack({ text: 'La formation a bien été supprimée', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression de la formation', type: 'warn' });
          });
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(num);
      },

      prenomNomFormateurs() {
        return this.formation.formateurs.map(function(f) {
          return f.prenom + ' ' + f.nom;
        }).join(', ');
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>

  #formation-detail >>> .align-right {
    text-align: right;
  }

  #formation-detail >>> .table-formation-detail th,
  #formation-detail >>> .table-formation-detail td {
    font-size: 0.9rem !important;
    text-align: left;
  }

  #formation-detail >>> .table-formation-detail th:first-child {
    width: 1%;
  }

  #formation-detail >>> .table-formation-detail th:nth-child(2) {
    width: 33%;
  }

  #formation-detail >>> #table-recettes-budgets-inter thead th:nth-child(3),
  #formation-detail >>> #table-recettes-budgets-intra-sstrt thead th:nth-child(2) {
    width: 120px;
  }

  #formation-detail >>> #table-recettes-budgets-inter thead th:nth-child(4),
  #formation-detail >>> #table-recettes-budgets-intra-sstrt thead th:nth-child(3) {
    width: 150px;
  }

  .color-gray {
    color: gray !important;
  }
  #formation-detail >>> .input-budget input {
    text-align: right;
  }

  #formation-detail >>> .see-more-btn {
    cursor: pointer;
    color: #00000080;
    text-decoration: underline dotted #00000080;
  }

  #formation-detail >>> .table-budget-total {
    background-color: --primary;
  }

  #formation-detail >>> .badge-chips {
    padding: 6px 12px;
    font-weight: 600;
    border-radius: 4px;
  }

  #formation-detail >>> .badge-blue {
    background-color: #1967c026;
    color: #135095;
  }

  #formation-detail >>> .badge-green {
    background-color: #00968826;
    color: #02643f;
  }

  #formation-detail >>> .badge-indigo {
    background-color: #3f51b526;
    color: #2e3c8a;
  }

  #formation-detail >>> .badge-purple {
    background-color: #9c28b026;
    color: #7b1f8a;
  }

  #formation-detail >>> .badge-red {
    background-color: #e9316226;
    color: #bb274f;
  }
  #formation-detail >>> .blinking {
    animation: blinker 3s ease-out infinite;
  }

  #formation-detail >>> .badge-blue-outline {
    border: solid 1px #4b70ff;
    color: #4b70ff;
  }

  #formation-detail >>> .badge-yellow-outline {
    border: solid 1px #c95017;
    color: #c95017;
  }

  #formation-detail >>> .badge-gray-outline {
    border: solid 1px #797b86;
    color: #797b86;
  }

  #formation-detail >>> .bloc-facture {
    border: solid 1px #e0e1e0;
    padding: 10px;
    border-radius: 4px;
  }

  #formation-detail >>> .no-underline {
    text-decoration: none;
  }

  #formation-detail >>> .table-factures tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #formation-detail >>> .table-factures tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .table-factures >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: bold;
  }

  .table-factures >>> .badge-gray {
    background-color: #a6a8ae26;
    color: #63646a;
  }

  .table-factures >>> .badge-blue {
    background-color: #1967c026;
    color: #135095;
  }

  .table-factures >>> .badge-green-2 {
    background-color: #00968826;
    color: #02643f;
  }

  .table-factures >>> .badge-orange {
    background-color: #e88f1326;
    color: #905709;
  }
  #formation-detail >>> .a-completer {
    font-style: italic;
    color: gray;
  }

  #formation-detail >>> .input-stagiaires-anonymes {
    background-color: #fff;
    font-size: 1em;
    width: 5em;
  }

  #formation-detail >>> .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #f1f1f5;
    margin: 4px 0;
    border-radius: 30px;
  }

  #formation-detail >>> .v-expansion-panel::before {
    box-shadow: none;
  }

  #formation-detail >>> .v-expansion-panel:not(:first-child)::after {
    border-top: none;
  }
</style>
